<template>
  <div class="add-role">
    <div class="main">
      <!--      <div class="mainheader">新建角色</div>-->
      <div class="addRoleForm">
        <div class="btn">
          <!-- <span @click="returnToList"><img src="/static/images/aoya/cancel.png" alt="" >取消</span>
          <span @click="handleSubmit('formCustom')"><img src="/static/images/aoya/save.png" alt="" >保存</span>-->
          <div class="actBtn" @click="returnToList">
            <i class="iconfont iconicon-quxiao"></i>取消
          </div>
          <div class="actBtn" @click="handleSubmit('formCustom')">
            <i class="iconfont iconicion-baocun"></i> 保存
          </div>
        </div>
        <div class="form">
          <Form
            ref="formCustom"
            :model="formCustom"
            :rules="ruleCustom"
            style="width: 600px"
            :label-width="80"
          >
            <FormItem label="角色名称" prop="roleName">
              <Input
                type="text"
                v-model="formCustom.roleName"
                placeholder="请输入角色名称，最多20个字符"
                :maxlength="21"
              ></Input>
            </FormItem>
            <!-- <FormItem label="刷卡权限" prop="cardType" style="margin:25px 0;">
              <Select
                v-model="formCustom.cardType"
                placeholder="请选择平板刷卡权限"
              >
                <Option
                  v-for="item in cardTypes"
                  :key="item.type"
                  :value="item.type"
                  >{{ item.value }}</Option
                >
              </Select>
            </FormItem> -->
            <FormItem label="职能描述" prop="des">
              <Input
                placeholder="请输入职能描述，最多200字"
                type="textarea"
                :rows="3"
                :autosize="{ maxRows: 3, minRows: 3 }"
                v-model="formCustom.des"
                :maxlength="200"
              ></Input>
            </FormItem>
          </Form>
        </div>
      </div>
      <div class="addRoleCheck">
        <div class="tree">
          <div class="header">菜单栏功能选项</div>
          <div class="inner">
            <el-tree
              ref="dataTree"
              :data="treeData"
              node-key="authorityId"
              show-checkbox
              :default-checked-keys="defaultCheckData"
              @check="checkHandle"
              :props="defaultProps"
            ></el-tree>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Qs from "qs";
export default {
  data() {
    return {
      cardType: 0, // 平板刷卡权限 0 无法刷卡  1 平板穿纱 2 平板改机 3 平板量产 4 平板报修 5 平板维修
      cardTypes: [
        {
          type: 0,
          value: "无需刷卡"
        },
        {
          type: 1,
          value: "平板穿纱权限"
        },
        {
          type: 2,
          value: "平板改机权限"
        },
        {
          type: 3,
          value: "平板量产权限"
        },
        {
          type: 4,
          value: "平板报修权限"
        },
        {
          type: 5,
          value: "平板维修权限"
        }
      ],
      roleId: null,
      formCustom: {
        cardType: "",
        roleName: "",
        des: ""
      },
      authorityLists: [1, 58, 59, 60],
      ruleCustom: {
        roleName: [
          { required: true, trigger: "blur", message: "此项必填！" },
          { type: "string", max: 20, message: "名称不能超过 20 个字符" }
        ],
        des: [
          { trigger: "blur" },
          { type: "string", max: 200, message: "描述不能超过 200 个字符" }
        ],
        cardType: [
          {
            required: true,
            type: "number",
            message: "请选择平板刷卡权限",
            trigger: "change"
          }
        ]
      },
      treeData: [],
      defaultCheckData: [1, 58, 59, 60],
      defaultProps: {
        children: "list",
        label: "authorityName"
      }
    };
  },
  methods: {
    // 返回或新增返回列表页
    returnToList() {
      this.$router.push({
        path: "/systemManagement/authority"
      });
    },
    // 请求菜单数据
    authorityList() {
      this.axios({
        url: "/dtsum/zongtong/organization/RoleManagerController/authorityList",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.treeData = res.data.body.authorityList;
            this.treeData.forEach((item) => {
              if (item.authorityId === 58 || item.authorityId === 1) {
                item.disabled = true;
                if (item.list) {
                  item.list.forEach((itemin) => {
                    itemin.disabled = true;
                  });
                }
              }
            });
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(`新建角色权限功能列表:${error}`);
        });
    },
    // 查询角色详情
    roleDetail() {
      const params = {
        roleId: this.roleId === null || "" ? undefined : this.roleId
      };
      this.axios({
        url: "/dtsum/zongtong/organization/RoleManagerController/roleDetail",
        method: "get",
        params: params
      })
        .then((res) => {
          if (res.data.success === 1) {
            // 获取名称和描述
            const data = res.data.body.roleInfo;
            this.formCustom.roleName = data.ztrName;
            this.formCustom.des = data.ztrDesc;
            // 刷卡刷卡权限
            this.formCustom.cardType = parseInt(data.ztrCardType); // 2019.12.19修改

            // 获取已有权限
            const authData = res.data.body.authorityList;
            const authDataArr = [];
            for (const i in authData) {
              if (authData[i].check === 1) {
                authDataArr.push(authData[i].authorityId);
                for (const j in authData[i].list) {
                  authDataArr.push(authData[i].list[j].authorityId);
                  for (const k in authData[i].list[j].list) {
                    authDataArr.push(authData[i].list[j].list[k].authorityId);
                  }
                }
              } else {
                for (const j in authData[i].list) {
                  if (authData[i].list[j].check === 1) {
                    authDataArr.push(authData[i].list[j].authorityId);
                    for (const k in authData[i].list[j].list) {
                      authDataArr.push(authData[i].list[j].list[k].authorityId);
                    }
                  } else {
                    for (const k in authData[i].list[j].list) {
                      if (authData[i].list[j].list[k].check === 1) {
                        authDataArr.push(
                          authData[i].list[j].list[k].authorityId
                        );
                      }
                    }
                  }
                }
              }
            }
            console.log(authDataArr);
            this.defaultCheckData = authDataArr;
            this.authorityLists = this.defaultCheckData;
            console.log(this.authorityLists);
            this.defaultCheckData.some((item) => {
              if (item === 58 || item === 1) {
              } else {
                this.defaultCheckData.push(1, 58, 59, 60);
              }
            });
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(`查询角色详情:${error}`);
        });
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (!this.roleId) {
            this.axios({
              method: "post",
              url: '/dtsum/zongtong/organization/RoleManagerController/newRole',
              data: {
                roleName: this.formCustom.roleName,
                cardType: this.formCustom.cardType, // 2019.12.19修改 保存角色时添加是否具有平板刷卡权限
                roleDescribe: this.formCustom.des,
                authorityList: this.authorityLists + ""
              },
              transformRequest: [
                function(data) {
                  // Object.keys(data).forEach((key) => {
                  //   if (typeof data[key] === "object") {
                  //     data[key] = JSON.stringify(data[key]);
                  //   }
                  // });
                  data = Qs.stringify(data, { arrayFormat: "repeat" });
                  return data;
                }
              ]
            })
              .then((res) => {
                if (res.data.success === 1) {
                  this.$Message.success("保存成功！");
                  // this.returnToList();
                } else {
                  this.$Message.error(res.data.msg);
                }
              })
              .catch((error) => {
                console.log(`新建错误信息:${error}`);
              });
          } else {
            this.axios({
              url: "/dtsum/zongtong/organization/RoleManagerController/editRole",
              method: "get",
              params: {
                roleId: this.roleId,
                roleName: this.formCustom.roleName,
                cardType: this.formCustom.cardType, // 2019.12.19修改 保存角色时添加是否具有平板刷卡权限
                roleDescribe: this.formCustom.des,
                authorityList:
                  this.authorityLists.length === 0
                    ? this.defaultCheckData
                    : this.authorityLists
              },
              paramsSerializer: function(params) {
                return Qs.stringify(params, { arrayFormat: "repeat" });
              }
            })
              .then((res) => {
                if (res.data.success === 1) {
                  this.$Message.success("修改成功！");
                  this.returnToList();
                } else {
                  this.$Message.error(res.data.msg);
                }
              })
              .catch((error) => {
                console.log(`修改信息:${error}`);
              });
          }
        } else {
          this.$Message.error("保存失败!不符合规则");
        }
      });
    },
    // 获取所有已选择 id 的 array
    checkHandle(node, data) {
      // 勾选的key
      var checkedKeys = this.$refs.dataTree.getCheckedKeys();
      this.authorityLists = checkedKeys;
    }
  },
  watch: {},
  created() {
    this.roleId = this.$route.query.id;
    if (this.roleId) {
      this.roleDetail();
    }
    this.authorityList();
  }
};
</script>

<style lang="scss" scoped>
.main {
  ::-webkit-input-placeholder {
    color: #212b36;
  }
  .addRoleForm {
    width: 100%;
    height: 300px;
    .btn {
      width: 100%;
      height: 30px;
      margin-bottom: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(99, 115, 129, 1);
        cursor: pointer;
      }
      span:hover {
        color: #2980b9;
      }
      span:first-child {
        margin-left: 20px;
        margin-right: 10px;
      }
      .actBtn {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(99, 115, 129, 1);
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      .actBtn:first-child {
        margin-left: 20px;
        margin-right: 10px;
      }
    }
    .form {
      margin-left: 40px;
    }
  }
  .addRoleCheck {
    box-sizing: border-box;
    width: calc(100% - 32px);
    height: 55vh;
    /*padding: 16px;*/
    margin: 0 auto;
    border: 1px solid rgba(223, 227, 232, 1);
    .tree {
      float: left;
      width: 100%;
      height: 100%;
      .header {
        width: 100%;
        height: 56px;
        background-color: rgba(247, 250, 255, 1);
        border-bottom: 1px solid rgba(223, 227, 232, 1);
        border-right: 1px solid rgba(223, 227, 232, 1);
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 56px;
        padding-left: 20px;
      }
      .inner {
        width: 100%;
        height: calc(100% - 56px);
        border-right: 1px solid rgba(223, 227, 232, 1);
        overflow: hidden;
        overflow-y: scroll;
        padding-top: 16px;
      }
    }
    .checkBox {
      float: left;
      width: 0;
      height: 100%;
      .header {
        width: 100%;
        height: 56px;
        background-color: rgba(247, 250, 255, 1);
        border-bottom: 1px solid rgba(223, 227, 232, 1);
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 56px;
        padding-left: 20px;
      }
      .inner {
        width: 100%;
        height: calc(100% - 56px);
        padding: 16px;
      }
    }
  }
}
</style>

<style lang="scss">
.add-role {
  .el-table_header {
    thead {
      tr {
        th {
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
  }
  .el-tree-node__content {
    border: 0 none !important;
    min-height: 0 !important;
  }
}
</style>
